import styled from '@emotion/styled'
import { Props as ButtonProps } from 'app/components/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  categories?: (string | undefined)[]
  cta: ButtonProps
  languageCode: string
  image?: ImageProps
  created: string
  tags?: (string | undefined)[]
  text?: string
  title: string
  URL: string
}

export const Article = memo(function Article({
  categories,
  cta,
  created,
  image,
  languageCode,
  tags,
  text,
  title,
  URL,
}: Props) {
  const publishedOn = new Date(created)

  return (
    <Container>
      <Anchor to={URL}>
        <h2>{title}</h2>
        <PublishedOn>
          {new Intl.DateTimeFormat(languageCode, {
            month: 'short',
          }).format(publishedOn)}
          <br />
          {new Intl.DateTimeFormat(languageCode, {
            day: '2-digit',
          }).format(publishedOn)}
        </PublishedOn>
        <Wrapper row wrap>
          {image ? (
            <Background>
              <LazyLoadComponent>
                <Image {...image} />
              </LazyLoadComponent>
            </Background>
          ) : null}
          <IntroTextContainer dial={6}>
            {text ? (
              <Text
                dangerouslySetInnerHTML={{
                  __html: text.replace(/(<([^>]+)>)/gi, ''),
                }}
              />
            ) : null}
            <CTA>{cta.label}</CTA>
          </IntroTextContainer>
        </Wrapper>
      </Anchor>
      <Footer row>
        {categories && categories.length > 0 ? (
          <div>
            <b>{useVocabularyData('published-on', languageCode)}</b>
            {categories.map((item, index) => (
              <span key={index}>
                <ButtonFilter
                  className="blog-button-filter"
                  datatype="categories"
                  value={item}
                >
                  {item}
                </ButtonFilter>
                {index < categories.length - 1 ? ', ' : ''}
              </span>
            ))}
          </div>
        ) : null}
        {tags && tags.length > 0 ? (
          <div>
            <b>{useVocabularyData('tagged-with', languageCode)}</b>
            {tags.map((item, index) => (
              <span key={index}>
                <ButtonFilter
                  className="blog-button-filter"
                  datatype="tags"
                  value={item}
                >
                  {item}
                </ButtonFilter>
                {index < tags.length - 1 ? ', ' : ''}
              </span>
            ))}
          </div>
        ) : null}
      </Footer>
    </Container>
  )
})

const Container = styled.section`
  display: block;
  position: relative;
  text-align: left;
  margin-bottom: 3rem;
  padding: 0 0 3rem 4rem;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    &:after {
      display: none;
    }
  }
  &:after {
    content: '';
    height: 1px;
    background-color: #dedede;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 4rem;
  }

  @media all and (max-width: 767px) {
    padding: 0;
    &:after {
      left: 0;
    }
  }
`

const Anchor = styled(Link)`
  > h2 {
    font-family: ${({ theme }) => theme.fontFamily.heading};
    font-size: 2.5rem;
    font-weight: 700;

    @media all and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }
`

const PublishedOn = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: #999;
  font-size: 1.125rem;
  position: absolute;
  top: 0.25rem;
  left: 0;
  text-align: center;
  text-transform: uppercase;

  @media all and (max-width: 767px) {
    display: none;
  }
`

const Wrapper = styled(FlexBox)`
  margin-top: 1.5rem;

  @media all and (max-width: 400px) {
    flex-direction: column;
    margin-top: 1rem;
  }
`

const Background = styled.div`
  width: 35%;
  background-color: #ccc;
  padding-bottom: 25%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media all and (max-width: 400px) {
    width: 100%;
    margin-bottom: 1rem;
    padding-bottom: 0;
    height: 50vw;
  }
`

const IntroTextContainer = styled(FlexBox)`
  width: 65%;
  padding-left: 2rem;

  button {
    background: black;
    margin-top: 2.5rem;
  }

  @media all and (max-width: 400px) {
    width: 100%;
    padding-left: 0;
  }
`

const CTA = styled.div`
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1em;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.base};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.variants.default};
  margin-top: 2rem;
  padding: 0.75rem 1rem;
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.variants.primary};
    color: black;
  }
`

const Text = styled.div`
  max-height: 6.5rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.625rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`

const Footer = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  color: #555;
  margin-top: 1.5rem;
  > div {
    margin-right: 1rem;
    b {
      margin-right: 0.3125rem;
    }
  }

  @media all and (max-width: 767px) {
    padding-bottom: 24px;
  }
`

const ButtonFilter = styled.button``
