import styled from '@emotion/styled'
import { BlogArticlePreview } from 'app/components/BlogArticlePreview'
import { BlogCategoriesList } from 'app/components/BlogCategoriesList'
import { BlogSearchList } from 'app/components/BlogSearchList'
import { BlogTagsList } from 'app/components/BlogTagsList'
import { FlexBox } from 'app/components/FlexBox'
import { RecentArticles } from 'app/components/RecentArticles'
import { SEO } from 'app/components/SEO'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import {
  MainLeadGeneration,
  Props as MainLeadGenerationProps,
} from 'app/containers/MainLeadGeneration'
import { Navigation, Props as NavigationProps } from 'app/containers/Navigation'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  navigationProps?: NavigationProps
  mainLeadGenerationProps?: MainLeadGenerationProps
  footerProps?: FooterProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function BlogPageTemplate({
  pageContext,
}: PageProps<void, PageContext>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.navigationProps ? (
        <Navigation
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.navigationProps}
        />
      ) : null}
      <Title>
        <span>Blog</span>
      </Title>
      <Wrapper row>
        <Content>
          {context.articlePreviewProps ? (
            <BlogArticlePreview {...context.articlePreviewProps} />
          ) : null}
        </Content>
        <Sidebar>
          <BlogSearchList languageCode={pageContext.languageCode} />
          {context.recentArticlesProps ? (
            <RecentArticles {...context.recentArticlesProps} />
          ) : null}
          {context.categoriesListProps ? (
            <BlogCategoriesList {...context.categoriesListProps} />
          ) : null}
          {context.tagsListProps ? (
            <BlogTagsList {...context.tagsListProps} />
          ) : null}
        </Sidebar>
      </Wrapper>
      {context.mainLeadGenerationProps ? (
        <MainLeadGeneration
          languageCode={pageContext.languageCode}
          {...context.mainLeadGenerationProps}
        />
      ) : null}
      {context.footerProps ? (
        <Footer
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``

const Wrapper = styled(FlexBox)`
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 4rem 2rem;

  @media all and (max-width: 767px) {
    flex-direction: column;
  }
`

const Content = styled.div`
  width: 70%;
  border-right: 1px solid #dedede;
  padding-right: 2rem;

  @media all and (max-width: 767px) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #dedede;
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    padding-right: 0;
  }
`

const Sidebar = styled.div`
  width: 25%;
  padding-left: 2rem;

  @media all and (max-width: 767px) {
    width: 100%;
    padding: 0;
  }
`

const Title = styled.h1`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  background-color: black;
  color: ${({ theme }) => theme.colors.variants.base};
  span {
    display: block;
    max-width: 1400px;
    margin: auto;
    padding: 2rem;
  }
`
