import styled from '@emotion/styled'
import { FlexBox } from 'app/components/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  tags: string[]
  languageCode: string
}

export const BlogTagsList = memo(function BlogTagsList({
  tags,
  languageCode,
}: Props) {
  if (tags.length < 1) {
    return null
  }

  return (
    <Container>
      <Title>{useVocabularyData('tags', languageCode)}</Title>
      <List>
        {tags.map((item, index) => (
          <Item key={index}>
            <ButtonFilter
              className="blog-button-filter"
              datatype="tags"
              value={item}
            >
              {item}
            </ButtonFilter>
          </Item>
        ))}
      </List>
    </Container>
  )
})

const Container = styled(FlexBox)`
  padding-top: 2rem;
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 700;
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
`

const Item = styled.li`
  margin: 0.5rem 0.5rem 0 0;
`

const ButtonFilter = styled.button`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  background: #dedede;
  padding: 0.3rem 0.5rem 0.2rem 0.5rem;
  border-radius: 4px;
  transition: 0.3s;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.primary};
  }
`
