import styled from '@emotion/styled'
import { FlexBox } from 'app/components/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  languageCode: string
}

export const BlogSearchList = memo(function BlogSearchList({
  languageCode,
}: Props) {
  return (
    <Container>
      <Title>{useVocabularyData('search', languageCode)}</Title>
      <Wrapper dial={2} row space="between">
        <Search className="blog-search-input" type="text" datatype="search" />
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  border-bottom: 1px solid #dedede;
  padding-bottom: 2rem;
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 700;
`

const Wrapper = styled(FlexBox)`
  width: 100%;
  margin-top: 1rem;
`

const Search = styled.input`
  width: 100%;
  height: 36px;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.938rem;
  border: 1px solid #dedede;
  padding: 0 0.5rem;
`
