import styled from '@emotion/styled'
import React, { memo, useEffect, useState } from 'react'

import { Article, Props as ArticleProps } from './Article'

export interface Props {
  articles: ArticleProps[]
  languageCode: string
}

export const BlogArticlePreview = memo(function BlogArticlePreview({
  articles,
  languageCode,
}: Props) {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchType, setSearchType] = useState('')
  const [searchResults, setSearchResults] = useState(articles)

  const handleChange = (e: any) => {
    setSearchType(e.target.getAttribute('datatype'))
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    const results = articles.filter((t) => {
      switch (searchType) {
        case 'search': {
          return searchTerm.length > 2
            ? t.title
                .toLocaleLowerCase()
                .includes(searchTerm.toLocaleLowerCase())
            : articles
          break
        }
        case 'tags': {
          return t.tags?.includes(searchTerm)
          break
        }
        case 'categories': {
          return t.categories?.includes(searchTerm)
          break
        }
        default: {
          return articles
          break
        }
      }
    })

    setSearchResults(results)
  }, [searchTerm])

  if (typeof document !== 'undefined') {
    document
      .querySelectorAll('.blog-button-filter')
      .forEach((e) => e.addEventListener('click', handleChange))

    document
      .querySelectorAll('.blog-search-input')
      .forEach((e) => e.addEventListener('keyup', handleChange))
  }

  return (
    <Container>
      {searchResults.map((item, index) => (
        <Article key={index} {...item} languageCode={languageCode} />
      ))}
    </Container>
  )
})

const Container = styled.section``
